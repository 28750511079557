<template>
  <div class="about">
    <h1>This is an about page</h1>
    <button @click='test'>Cek Resolusi</button>
  </div>
</template>

<script>
export default {
  methods: {
    test () {
      alert(`Inner Width: ${window.innerWidth} Inner Height: ${window.innerHeight}`)
    }
  }
}
</script>
